import React from "react"
import * as Icon from "react-feather"

import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const TeamStyleTwo = () => {
  return (
    <div className="team-area repair-team-area pt-80 pb-50 #f5f4f6">
      <div className="container">
        <div className="section-title">
          <h2>Our Executive Team</h2>
          <div className="bar"></div>
          <p>
            We solve complex problems with expert teams of experienced people.
          </p>
        </div>

        <StaticQuery
          query={graphql`
            query MyQuery {
              allTeammemberJson {
                edges {
                  node {
                    id
                    socialLink
                    role
                    description
                    name
                    image {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          height: 125
                          width: 125
                          formats: AUTO
                          quality: 90
                          transformOptions: { fit: COVER }
                        )
                      }
                    }
                  }
                }
              }
            }
          `}
          render={data => (
            <div className="row justify-content-center">
              {data.allTeammemberJson.edges.map(({ node }) => {
                return (
                  <div key={node.id} className="col-lg-4 col-md-6">
                    <div className="single-team">
                      <div className="team-image">
                        <GatsbyImage
                          image={getImage(node.image)}
                          alt={node.name}
                          className="single-img"
                        />
                      </div>
                      <div className="team-content">
                        <div className="team-info">
                          <h3>{node.name}</h3>
                          <span>{node.role}</span>
                        </div>

                        <ul className="social-links">
                          <li>
                            <a
                              href={node.socialLink}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Icon.Linkedin />
                            </a>
                          </li>
                        </ul>

                        <p>{node.description}</p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        />
      </div>
    </div>
  )
}

export default TeamStyleTwo
