import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify";
import Layout from "../components/_App/layout";
import Seo from "../components/_App/seo";
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import WhyJoinAtyeti from "../components/Careers/WhyJoinAtyeti";
import { StaticImage } from "gatsby-plugin-image";

// Page Style Overrides Component
const StyleOverrides = () => (
  <style jsx>{`
    #bambooJobs h2 {
      font-size: 2rem;
      color: #323232;
      font-weight: 700;
      font-family: Poppins, sans-serif;
      padding-top: 1rem;
    }
    #bambooJobs a {
      color: #0d6efd;
    }
    .BambooHR-ATS-Department-Header {
      font-size: 1.1rem;
      color: #323232;
      font-weight: 700;
      font-family: Poppins, sans-serif;
      padding-top: 0.5rem;
    }
    .BambooHR-ATS-Jobs-Item {
        padding: 0.2rem;
    }
    .BambooHR-ATS-Jobs-Item span {
        padding: 0 !important;
    }
    .BambooHR-ATS-Jobs-Item:hover {
        background-color: #eee;
    }
  `}</style>
);

// Component for the main banner
const MainBanner = () => (
  <div className="agency-main-banner">
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-lg-6 col-md-12">
          <div className="agency-banner-content">
            <h3>Career Opportunities</h3>
            <p>We are always looking for the best and brightest individuals.</p>
            <p>
              We're committed to finding the best opportunity for everyone. Your next customer project can be anywhere in the world- that's so exciting!!!!
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="agency-banner-image">
            <StaticImage
              src="../assets/images/career.png"
              alt="Career Opportunities"
              loading="eager"
            />
            <p></p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

// Component for the call to action area
const CallToAction = () => (
  <div className="repair-cta-area bg-003c64">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-sm-12">
          <div className="cta-repair-content">
            <h2>ARE YOU READY FOR THE NEXT STEP IN YOUR CAREER?</h2>
            <p style={{ paddingTop: "15px" }}>
              Atyeti allows you to work in a fast growing exciting company, while being able to work with industry leading Fortune 500 companies doing a lot of digital transformation work.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

// BambooHR Embed Component
function JobList() { 
  const [htmlContent, setHtmlContent] = useState('');
  
  useEffect(() => {
    fetch(`https://atyeti.bamboohr.com/jobs/embed2.php?version=1.0.0`)
      .then(response => response.text())
      .then(data => {
        const sanitizedHtml = DOMPurify.sanitize(data);
        setHtmlContent(sanitizedHtml);
      });
  }, []);
  
  return (
    <div className="container">
      <div className="row align-items-center">
        <div className="col-sm-12">
          <div id="bambooJobs">
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </div>
        </div>
      </div>
    </div>
  );
}

const Career = () => {
  return (
    <Layout>
      <Seo
        title="Careers"
        description="Are you ready for the next step in your career? Atyeti is committed to finding the best opportunity for everyone. Your next job can be anywhere in the world- that's so exciting!!!!"
        keyword="careers, career opportunity, jobs, job openings, open positions, compensation, medical insurance, flexible work-life balance, training"
      />
      <StyleOverrides />
      <Navbar />
      <MainBanner />
      <CallToAction />
      <WhyJoinAtyeti pt-50 />
      <JobList />
      <Footer />
    </Layout>
  );
};

export default Career;